jQuery(document).ready(function ($) {
  $('#toestemming').on('change blur', function () {
    if ($(this).is(':checked')) {
      $('#checkbox').addClass('checked');
    } else {
      $('#checkbox').removeClass('checked');
    }
  });
  $('#register_form')
    // to prevent form from submitting upon successful validation
    .on('submit', function (ev) {
      ev.preventDefault();
      $('body').css('cursor', 'wait');
      $('#error-message').text('');
      $('#register_form button .text').hide();
      $('#register_form button #loading').show();
      $('#register_form button').attr('disabled', 'true');
      //set cursor to wait
      $('body').css('cursor', 'wait');
      var that = $('#register_form'),
        url = that.attr('action'),
        method = that.attr('method'),
        data = {};

      that.find('[name]').each(function () {
        var that = $(this),
          name = that.attr('name'),
          value = that.val();
        data[name] = value;
      });

      //check if #toestemming is checked
      if ($('#toestemming').is(':checked')) {
        data.toestemming = 'yes';

        console.log(data);
        $.ajax({
          url: url,
          type: method,
          data: data,
          success: function (response) {
            console.log(response);
            response = JSON.parse(response);
            console.log(response);
            if (response.status === 'success') {
              $('#error-message').hide();
              //replace #main with the html of response.prize_html
              $('#main').html(response.prize_html);
              //set a div within that new html to display the code returned with response.code
              $('#main #code').text(response.code);
            } else if (response.status === 'code_used') {
              $('#error-message').text(
                'Jouw code is al gebruikt. Kijk je mailbox na of je een van de winnaars bent.'
              );
            } else if (response.status === 'code_not_found') {
              $('#error-message').text(
                'Jouw code is ongeldig. Controleer je code en probeer nogmaals.'
              );
            }
            $('#register_form button .text').show();
            $('#register_form button #loading').hide();
            $('#register_form button').removeAttr('disabled');
            $('body').css('cursor', 'default');
          },
          error: function (response) {
            $('#register_form button .text').show();
            $('#register_form button #loading').hide();
            $('#register_form button').removeAttr('disabled');
            $('#error-message').text('Mislukt, probeer nogmaals.');
            console.log(response);
          },
        });
      } else {
        data.toestemming = 'no';
        $('#register_form button #loading').hide();
        $('#register_form button .text').show();
        $('#register_form button').removeAttr('disabled');
        $('body').css('cursor', 'default');
        $('#error-message').text(
          'Gelieve toestemming te geven om onze mails te ontvangen.'
        );
      }
    });
});
